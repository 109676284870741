import { useMemo, useState } from "react"

import { TCreateClientForm } from "src/components/Paradise/ParadiseClients/ParadiseCreateClientDialog/ParadiseCreateClientDialog"
import { OwnerType } from "src/data/integrations/types/integrationTypes"
import { useFetchOrganizations } from "src/data/organizations/queries/organizationQueries"
import { useFetchParadiseUsers } from "src/data/paradise/paradiseUsers/queries/paradiseUserQueries"
import { MSelect } from "src/ui/MSelect/MSelect"
import { Maybe } from "src/utils/tsUtil"

export function ParadiseCreateClientOwner({
  owner,
  ownerType,
  onChange,
  disabled,
}: {
  owner: Maybe<TCreateClientForm["owner"]>
  ownerType: OwnerType
  onChange: (selectedOwner: Omit<TCreateClientForm["owner"], "type">) => void
  disabled?: boolean
}) {
  const [searchedInput, setSearchedInput] = useState("")

  const fetchUsers = useFetchParadiseUsers({
    filter: {
      email: searchedInput,
    },
    options: {
      enabled: ownerType === OwnerType.USER,
    },
  })

  const fetchOrganizations = useFetchOrganizations({
    params: {
      id: searchedInput,
    },
    options: {
      enabled: ownerType === OwnerType.ORGANIZATION,
    },
  })

  const value = useMemo(() => {
    if (owner?.id) {
      return {
        id: owner.id,
        name: owner.email,
      }
    }

    return null
  }, [owner])

  const options = useMemo(() => {
    if (ownerType === OwnerType.ORGANIZATION) {
      return (
        fetchOrganizations.data?.organizations.map((org) => ({
          id: org.id,
          name: org.id,
        })) ?? []
      )
    }

    return (
      fetchUsers.data?.users.map((user) => ({
        id: user.user_id,
        name: user.email,
      })) ?? []
    )
  }, [ownerType, fetchOrganizations, fetchUsers])

  return (
    <MSelect
      value={value}
      options={options}
      loading={fetchUsers.isFetching || fetchOrganizations.isFetching}
      onSearch={setSearchedInput}
      onSelected={(selected) => {
        if (selected) {
          onChange({
            id: selected.id,
            email: selected.name,
          })
          setSearchedInput("")
        }
      }}
      required
      disabled={disabled}
    />
  )
}
