import styled from "styled-components"

import { Select, TextField } from "@material-ui/core"
import { useFormik } from "formik"

import { ParadiseClientOAuthSettings } from "src/components/Paradise/ParadiseClients/ParadiseCreateClientDialog/ParadiseClientOAuthSettings"
import { ParadiseClientSettings } from "src/components/Paradise/ParadiseClients/ParadiseCreateClientDialog/ParadiseClientSettings"
import { ParadiseCreateClientOwner } from "src/components/Paradise/ParadiseClients/ParadiseCreateClientDialog/ParadiseCreateClientOwner"
import { FormItem } from "src/components/Paradise/ParadiseClients/ParadiseCreateClientDialog/ParadiseFormItem"
import { usePostApiClient } from "src/data/apiClients/apiClientQueries"
import { OwnerType } from "src/data/integrations/types/integrationTypes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MDialog } from "src/ui/Dialog/MDialog"
import { ExpandableSection } from "src/ui/ExpandableSection/ExpandableSection"
import { InfoBox } from "src/ui/InfoBox/InfoBox"
import { spacing } from "src/ui/spacing"

const INITIAL_VALUE = {
  name: "",
  description: "",
  logo_url: "",
  owner: {
    type: OwnerType.USER,
    id: "",
    email: "",
  },
  enabled: true,
  // Client settings
  restricted_to_owner: true,
  roles: [],
  first_party: false,
  can_access_third_party_data: false,
  minimum_api_version: 8,
  user_required_role: "",
  // OAuth settings
  authorization_code_grant: {
    enabled: true,
    redirect_uris: [],
    allowed_origins: [],
    require_pkce: false,
  },
  client_credentials_grant: {
    enabled: true,
  },
  owner_password_grant: {
    enabled: false,
  },
}

export type TCreateClientForm = typeof INITIAL_VALUE

const CREATE_CLIENT_FORM_ID = "create_client_form"

export function ParadiseCreateClientDialog({
  open,
  onClose,
  fixedOwnerType,
  fixedOwnerId,
}: {
  open: boolean
  onClose: () => void
  fixedOwnerType?: OwnerType
  fixedOwnerId?: string
}) {
  const router = useRouter()

  const postAPIClient = usePostApiClient()

  const form = useFormik<typeof INITIAL_VALUE>({
    initialValues: {
      ...INITIAL_VALUE,
      owner: {
        id: fixedOwnerId || "",
        type: fixedOwnerType || OwnerType.USER,
        email: fixedOwnerId || "",
      },
    },
    onSubmit: (values) => {
      postAPIClient.mutate(
        {
          ...values,
          owner: {
            type: values.owner.type,
            id: values.owner.id,
          },
        },
        {
          onSuccess: (data) => {
            router.navigate(Routes.ParadiseClient.location(data.client_id))
          },
          onError: (error) => {
            form.setStatus("error")
          },
        }
      )
    },
  })

  return (
    <MDialog
      title="Create client"
      open={open}
      onClose={onClose}
      formId={CREATE_CLIENT_FORM_ID}
      confirmLabel="Create"
    >
      <form
        id={CREATE_CLIENT_FORM_ID}
        onSubmit={(e) => {
          e.preventDefault()
          form.handleSubmit()
        }}
      >
        <FieldsWrapper>
          {form.status === "error" && (
            <InfoBox type="warning" title="An error occured" />
          )}
          <FormItem
            title="Name"
            control={
              <TextField
                value={form.values.name}
                name="name"
                onChange={form.handleChange}
                required
                fullWidth
              />
            }
          />
          <FormItem
            title="Description"
            control={
              <TextField
                value={form.values.description}
                name="description"
                onChange={form.handleChange}
                fullWidth
              />
            }
          />
          <FormItem
            title="Logo URL"
            control={
              <TextField
                value={form.values.logo_url}
                name="logo_url"
                onChange={form.handleChange}
                fullWidth
              />
            }
          />
          <FormItem
            title="Owner type"
            control={
              <Select
                native
                value={form.values.owner.type}
                name="owner.type"
                onChange={(e) => {
                  form.setFieldValue("owner.type", e.target.value as OwnerType)
                  form.setFieldValue("owner.id", "")
                  form.setFieldValue("owner.email", "")
                }}
                fullWidth
                disabled={!!fixedOwnerType}
              >
                <option value={OwnerType.USER}>User</option>
                <option value={OwnerType.ORGANIZATION}>Organization</option>
              </Select>
            }
          />
          <FormItem
            title="Owner"
            control={
              <ParadiseCreateClientOwner
                owner={form.values.owner}
                ownerType={form.values.owner.type}
                onChange={(selectedOwner) => {
                  form.setFieldValue("owner.id", selectedOwner.id)
                  form.setFieldValue("owner.email", selectedOwner.email)
                }}
                disabled={!!fixedOwnerId}
              />
            }
          />
          <ExpandableSection title="Client settings">
            <FieldsWrapper>
              <ParadiseClientSettings form={form} />
            </FieldsWrapper>
          </ExpandableSection>
          <ExpandableSection title="OAuth settings">
            <FieldsWrapper>
              <ParadiseClientOAuthSettings form={form} />
            </FieldsWrapper>
          </ExpandableSection>
        </FieldsWrapper>
      </form>
    </MDialog>
  )
}

const FieldsWrapper = styled.div`
  display: grid;
  gap: ${spacing.XL};
`
